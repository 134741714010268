module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","rs"],"defaultLanguage":"en","siteUrl":"https://orthojankovic.com/","i18nextOptions":{"debug":true,"fallbackLng":"en","supportedLngs":["en","rs"],"defaultNS":"translation","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
